import { ShowCookieBarContext } from "../store/showCookieBar";
import { useContext } from "react";

/**
 * Custom hook to use the ShowCookieBar context.
 *
 * @throws Will throw an error if the hook is used outside of a ShowCookieBarProvider.
 * @returns {ShowCookieBarState} The context value containing the state and setter function.
 */
export const useShowCookieBar = () => {
  const context = useContext(ShowCookieBarContext);
  if (context === undefined) {
    throw new Error(
      "useShowCookieBar must be used within a ShowCookieBarProvider"
    );
  }
  return context;
};
