import React, { ReactNode, createContext, useReducer } from "react";

interface IsHomeState {
  changeStyle: boolean;
  isAccOpen: boolean;
}

export interface IsHomeAction {
  type: "SET_CHANGE_STYLE" | "SET_IS_ACC_OPEN";
  payload: boolean;
}

const initialIsHomeState: IsHomeState = {
  changeStyle: false,
  isAccOpen: false,
};

const IsHomeContext = createContext<
  | {
      state: IsHomeState;
      dispatch: React.Dispatch<IsHomeAction>;
      customDispatch: (_action: IsHomeAction) => void; // Add this line
    }
  | undefined
>(undefined);

const isHomeReducer = (
  state: IsHomeState,
  action: IsHomeAction
): IsHomeState => {
  switch (action.type) {
    case "SET_CHANGE_STYLE":
      return {
        ...state,
        changeStyle: action.payload,
      };
    case "SET_IS_ACC_OPEN":
      return {
        ...state,
        isAccOpen: action.payload,
      };
    default:
      return state;
  }
};

const customDispatch: (
  _dispatch: React.Dispatch<IsHomeAction>
) => (_action: IsHomeAction) => void = (dispatch) => (action) => {
  dispatch(action);
};

const IsHomeProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(isHomeReducer, initialIsHomeState);

  return (
    <IsHomeContext.Provider
      value={{ state, dispatch, customDispatch: customDispatch(dispatch) }}
    >
      {children}
    </IsHomeContext.Provider>
  );
};

export { IsHomeProvider, IsHomeContext };
