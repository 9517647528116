import { ApplicationFormProvider } from "../../store/applicationForm";
import { IsHomeProvider } from "../../store/isHome";
import React from "react";
import { RequestACallbackProvider } from "../../store/requestACallback";
import { ShowCookieBarProvider } from "../../store/showCookieBar";

const CombinedProvider = ({ children }: { children: React.ReactNode }) => (
  <IsHomeProvider>
    <ShowCookieBarProvider>
      <RequestACallbackProvider>
        <ApplicationFormProvider>{children}</ApplicationFormProvider>
      </RequestACallbackProvider>
    </ShowCookieBarProvider>
  </IsHomeProvider>
);

export default CombinedProvider;
