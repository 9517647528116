import { IsHomeAction, IsHomeContext } from "../store/isHome";

import { useContext } from "react";

let customDispatch: ((_action: IsHomeAction) => void) | undefined;

export const initializeCustomDispatch = (
  dispatch: (_action: IsHomeAction) => void
) => {
  customDispatch = dispatch;
};

export const getCustomDispatch = () => customDispatch;

/**
 * Custom hook to use the IsHome context.
 *
 * @throws Will throw an error if the hook is used outside of a IsHomeProvider.
 * @returns {IsHomeState} The context value containing the state and setter function.
 */
export const useIsHome = () => {
  const context = useContext(IsHomeContext);
  if (context === undefined) {
    throw new Error("useIsHome must be used within a IsHomeProvider");
  }
  return context;
};
