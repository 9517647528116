import React, { useState } from "react";

import { Cookies } from "react-cookie-consent";
import { Script } from "gatsby";
import { useShowCookieBar } from "../../hooks/useShowCookieBar";

const ThirdPartyScripts = () => {
  const [gaScriptLoaded, setGaScriptLoaded] = useState(false);
  const { isCookieBarDismissed } = useShowCookieBar();

  // Gets which cookies have been accepted. Currently when the
  // user clicks accept, all cookies are accepted. Could do with
  // a cookie window at some point where users can accept / decline
  // individual cookies.
  const useAnalytics = Cookies.get("gatsby-gdpr-google-analytics");
  const useFacebook = Cookies.get("gatsby-gdpr-facebook-pixel");
  const useHotjar = Cookies.get("gatsby-gdpr-hotjar");
  const googleAccepted = useAnalytics && useAnalytics === "true";

  // Hotjar code, they don't have a consent mode like Google Analytics or Facebook
  // so this is declared here so it can be used in the initial script and when
  // the user accepts cookies.
  const hotjarCode = `(function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:${process.env.GATSBY_HOTJAR_ID},hjsv:${process.env.GATSBY_HOTJAR_SV}};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  return (
    <>
      {/**
       * Scripts have an off-main-thread strategy so the scripts can
       * be loaded on a web worker instead of the main thread. Stops
       * the page from slowing down. More information can be found here:
       * https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-script/#off-main-thread-strategy-experimental
       *
       * Removed off-main-thread functionality as this does not work with Azure
       * Using default strategy instead:
       * https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-script/#post-hydrate-strategy-default
       */}

      <Script id="gtag-defaults" async>
        {`
        //Google Analytics tag setup
        window.dataLayer = window.dataLayer || [];
        function gtag(){
          dataLayer.push(arguments);
        }
        
        //Consent for GDPR so cookies can be used, default is denied, 
        //only granted if consent cookie is true. 
        gtag('consent', 'default', {
          'ad_storage': '${googleAccepted ? "granted" : "denied"}',
          'ad_user_data': '${googleAccepted ? "granted" : "denied"}',
          'ad_personalization': '${googleAccepted ? "granted" : "denied"}',
          'analytics_storage': '${googleAccepted ? "granted" : "denied"}',
          'functionality_storage': '${googleAccepted ? "granted" : "denied"}',
          'personalization_storage': '${googleAccepted ? "granted" : "denied"}'
        });
        `}
      </Script>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`}
        onLoad={() => setGaScriptLoaded(true)}
        async
      />
      {gaScriptLoaded && (
        <Script id="gtag-setup" async>
          {`
          //Configuration 
          gtag('js', new Date());
          gtag('config', '${process.env.GATSBY_GOOGLE_ANALYTICS_ID}');
          
          //Data to sent to analytics
          gtag('event', 'page_view', { 
            page_path: location 
            ? location.pathname + location.search + location.hash 
            : undefined 
          });
        `}
        </Script>
      )}
      <Script id="third-party-script-config" async>
        {`
          //Facebook pixel script
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');

          //Configuration
          fbq('init', '${process.env.GATSBY_FACEBOOK_PIXEL_ID}');

          //Consent for GDPR so cookies can be used
          fbq('consent','${
            useFacebook && useFacebook === "true" ? "grant" : "revoke"
          }');
          
          //Data to send to Facebook
          fbq('track', 'PageView');

          //Hotjar doesn't have a consent setting like Google and Facebook 
          //so this script can only be used if the user accepts cookies
          ${useHotjar && useHotjar === "true" ? hotjarCode : ""}
        `}
      </Script>
      {/**
       * The following is only triggered when the customer interacts with the cookie bar.
       * If the consent has been granted, then it will make sure the script is defined for
       * the current page. Since this is dependent on the cookie bar, this is not off-main-thread.
       */}
      {isCookieBarDismissed && useHotjar && useHotjar === "true" && (
        <Script id="hotjarCode" defer>
          {hotjarCode}
        </Script>
      )}
      <Script
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="idle"
      />
    </>
  );
};

export default ThirdPartyScripts;
