import React, { ReactNode, createContext, useState } from "react";

/**
 * Interface for the state of the ShowCookieBar context.
 */
interface ShowCookieBarState {
  isCookieBarDismissed: boolean;
  setIsCookieBarDismissed: (_dismissed: boolean) => void;
}

export const ShowCookieBarContext = createContext<
  ShowCookieBarState | undefined
>(undefined);

/**
 * Provider component for the ShowCookieBar context.
 *
 * @param {Object} props - The props for the provider component.
 * @param {ReactNode} props.children - The child components to be wrapped by the provider.
 * @returns {JSX.Element} The provider component with the context value.
 */
export const ShowCookieBarProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isCookieBarDismissed, setIsCookieBarDismissed] = useState(false);

  const value = {
    isCookieBarDismissed,
    setIsCookieBarDismissed,
  };

  return (
    <ShowCookieBarContext.Provider value={value}>
      {children}
    </ShowCookieBarContext.Provider>
  );
};
